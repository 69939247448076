<style lang="scss" scoped>
/deep/ .comp-permission-role-el-dialog {
    margin-top: 6vh !important;
    width: 88%;
    max-width: 820px;
    .role-content {
        margin: 10px 15px;
    }
}
</style>

<template>
    <div>
        <el-dialog title="设置角色权限" custom-class="c-el-dialog comp-permission-role-el-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-tabs tab-position="left">
                <el-tab-pane :label="item.displayName" v-for="item in allPermissions" :key="item.name">
                    <div class="role-content">
                        <div class="mrgb15">
                            <el-checkbox v-model="item.isAllChecked" @change="(v) => { checkAll(v, item) }">全选</el-checkbox>
                        </div>
                        <div v-for="pitem in item.permissions" :key="pitem.name"
                            class="mrgt5 mrgb5" :class="{ 'mrgl20': pitem.parentName }">
                            <el-checkbox v-model="pitem.isGranted" :indeterminate="pitem.isIndeterminate"
                                @change="(v) => { onChange(v, pitem, item) }">
                                {{ pitem.displayName }}
                            </el-checkbox>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" plain @click="_save()">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funPermission from "@/api/permission"
export default {
    name: "compPermissionRole",
    mixins: [configMixins],
    data() {
        return {
            showDialog: false,
            dialogData: {},
            queryParams: {
                providerName: "R",
                providerKey: ""
            },
            allPermissions: []
        }
    },
    mounted() {

    },
    methods: {
        open(row) {
            this.queryParams.providerKey = row.name
            funPermission.getPermissions(this.queryParams).then(res => {
                this.allPermissions = res.groups.map(x => {
                    x.isAllChecked = true
                    x.permissions = x.permissions.map(p => {
                        p.isIndeterminate = false
                        if (x.isAllChecked) {
                            x.isAllChecked = p.isGranted
                        }
                        return p
                    })
                    return x
                })
                this.showDialog = true
            })
        },
        checkAll(val, item) {
            // 角色选中时，判断有下级则全部选择
            item.permissions = item.permissions.map(x => {
                x.isGranted = val
                return x
            })
        },
        onChange(val, pitem, item) {
            // 角色选中时，判断有下级则全部选择
            // console.log(val, pitem, item)
            item.permissions = item.permissions.map(x => {
                // 当前节点为下级，则设置状态
                if (x.parentName && x.parentName.indexOf(pitem.name) !== -1) {
                    x.isGranted = val
                }
                // 点击下级，默认把上一级选中
                if (val && x.name == pitem.parentName) {
                    x.isGranted = true
                }
                return x
            })
            // 判断全选按钮是否选中
            let isAllChecked = true
            for (let i in item.permissions) {
                if (!item.permissions[i].isGranted) {
                    isAllChecked = false
                    break
                }
            }
            item.isAllChecked = isAllChecked
        },
        _save() {
            let commitData = JSON.parse(JSON.stringify(this.queryParams))
            commitData.permissions = []
            for (let i in this.allPermissions) {
                this.allPermissions[i].permissions.map(x => {
                    commitData.permissions.push({
                        name: x.name,
                        isGranted: x.isGranted
                    })
                })
            }
            window.$common.fullLoading()
            funPermission.updatePermissions(commitData).then(res => {
                this.commonSuccess()
            })
        },
        _close() {
            this.showDialog = false
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData()
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>